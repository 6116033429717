import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Box from "../../Box/Box";
import Flex from "../../Flex/Flex";
import { H1, Body } from "../../Text/Text";
import theme from "../../../theme/theme";
import LogEvent from "../../../log/LogEvent";

const StyledFlex = styled(Flex)`
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    box-shadow: inset 0 0 0 1px ${({ borderColor }) => theme.colors[borderColor]};
  }
`;

const InfoCard = ({ variant, icon, stats, title, logEventProps, ...props }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="infoCard">
    <StyledFlex p={3} border="solid" borderWidth={1} borderColor={variant} borderRadius={8} minWidth={320} bg={`${variant}WithOpacity.08`} flexDirection={["row", "column"]} {...props}>
      <Flex justifyContent="center" alignItems="center" bg={variant} borderRadius="50%" width={48} height={48}>
        {icon || null}
      </Flex>
      <Box mt={[0, 0, 3]} ml={[3, 3, 0]}>
        <H1>{stats}</H1>
        <Body small medium color={theme.colors.darkestShade}>
          {title}
        </Body>
      </Box>
    </StyledFlex>
  </LogEvent>
);

InfoCard.defaultProps = {
  variant: "primary",
  icon: null,
};

InfoCard.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.node,
  stats: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node.isRequired,
};

export default InfoCard;
