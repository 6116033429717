import React from "react";
import styled, { css } from "styled-components";
import { border } from "styled-system";
import { Button as RButton } from "rebass/styled-components";
import BaseComponent from "../Base/BaseComponent";
import { H5 } from "../Text/Text";
import Box from "../Box/Box";
import LogEvent from "../../log/LogEvent";

const ButtonWrapper = styled(BaseComponent)`
  transition: background 0.1s ease;
  ${border};
  &:focus {
    outline: 0;
  }
  &:hover:enabled {
    background-color: ${({ bgHovered, theme }) => theme.colors[bgHovered]};
  }
  ${({ disableBounce }) =>
    !disableBounce &&
    css`
      &:active:enabled {
        transform: scale(0.96);
      }
    `}
  &:disabled {
    opacity: 0.7;
  }
`;

const Button = ({ startIcon, center, endIcon, children, color, disabled, disableBounce, logEventProps, ...props }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="link">
    <ButtonWrapper data-testid={props.testid} {...props} disabled={disabled} disableBounce={disableBounce} cursor={disabled ? "not-allowed" : "pointer"}>
      {startIcon && <Box mr={1}>{startIcon}</Box>}
      {typeof children === "string" ? <H5 color={color}>{children}</H5> : <Box>{children}</Box>}
      {endIcon && <Box ml={1}>{endIcon}</Box>}
    </ButtonWrapper>
  </LogEvent>
);

Button.defaultProps = {
  as: RButton,
  py: 1,
  px: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  disableBounce: false,
};

Button.propTypes = RButton.propTypes;

export default Button;
