import React from "react";
import Button from "./Button";
import OutlinedButton from "./OutlinedButton";

const SecondaryButton = (props) => <Button {...props} bg="lightestShade" color="darkestShade" />;

SecondaryButton.propTypes = Button.propTypes;

const SecondaryOutlinedButton = (props) => <OutlinedButton {...props} />;

SecondaryOutlinedButton.propTypes = Button.propTypes;

export { SecondaryButton, SecondaryOutlinedButton };
