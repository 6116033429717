import React, { useEffect } from "react";
import styled from "styled-components";
import Box from "../Box/Box";
import { useControlledState } from "../../hooks/useControlledState";

/**
 * When needed to create a controlled accordion for handling the state for all accordion, create an AccordionContainer component and update to controlled state in accordion component
 */

const AccordionHeader = ({ open, onToggle, render = () => {}, children, ...props }) => (
  <Box cursor="pointer" {...props} onClick={onToggle}>
    {render(open) || children}
  </Box>
);

/**
 * Fixed height with overflow-y hidden will cause children not display properly. Especially when children have custom logic to dynamically load/unload component.
 * Modify to fixed max-height instead.
 */
const AnimatedBox = styled(Box)`
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const AccordionBody = ({ open, children, ...props }) => <AnimatedBox maxHeight={open ? "auto" : 0}>{open && <Box {...props}>{children}</Box>}</AnimatedBox>;

/**
 *
 * AccordionHeader accepts component or string as a child. It accepts all the props as Box. If you want to have a custom header that requires access to open state you can use render method to pass the custom component
 */
const Accordion = ({ children, open: value, onClick: onHandleChange, accordionRef, ...props }) => {
  const [open, onChange] = useControlledState(value, {}, onHandleChange);
  const onClick = () => (!onHandleChange ? onChange(!open) : null);

  useEffect(() => {
    if (accordionRef) {
      accordionRef({ open, onChange });
    }
  }, [open]);

  return <Box {...props}>{React.Children.map(children, (child) => React.cloneElement(child, { open, onClick }))}</Box>;
};

export { Accordion, AccordionBody, AccordionHeader };
