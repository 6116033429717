export const directions = (open) => ({
  right: {
    transform: open ? "translateX(-100%)" : "translateX(0%)",
    top: 0,
    left: "100%",
  },
  left: {
    transform: open ? "translateX(100%)" : "translateX(-100%)",
    top: 0,
    right: "100%",
  },
});
