import { get } from "lodash";

/* Ensures unique graph doc ids across an array of pages
 */
const buildPaginatedResponse = (data, documentName, mapNode, params = {}) => {
  const uniqueIds = [];
  const res = {
    ...data,
    totalCount: get(data, `pages[0].${documentName}.totalCount`),
    pageInfo: get(data, `pages[0].${documentName}.pageInfo`, {}),
    pages: get(data, "pages", []).map(({ [documentName]: graphResponse }) => {
      const { edges } = graphResponse || {};
      return (edges || [])
        .filter(({ node } = {}) => {
          if (!node || !node.uid || uniqueIds.includes(node.uid)) {
            return false;
          }
          uniqueIds.push(node.uid);
          return true;
        })
        .map(({ node }) => (mapNode ? mapNode(node, params) : node));
    }),
  };
  return res;
};

export { buildPaginatedResponse };
