import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { useControlledState } from "../../hooks/useControlledState";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

import Box from "../Box/Box";

const MODIFIERS = {
  OFFSET: {
    name: "offset",
    options: {
      offset: [10, 10],
    },
  },
  OFFSET_CENTERED: {
    name: "offset",
    options: {
      offset: [0, 10],
    },
  },
};

const Dropdown = ({ label, open: isOpen, onOpenChange, containerStyle, isContentCentered = false, onClose, dropdownRef, popperStyle = {}, ...props }) => {
  const [open, setOpen] = useControlledState(isOpen, false, onOpenChange);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: isContentCentered ? [MODIFIERS.OFFSET_CENTERED] : [MODIFIERS.OFFSET],
  });

  const [isActive, setIsActive] = useDetectOutsideClick(referenceElement, popperElement, open);

  const handleOpen = () => {
    setOpen(!open);
    setIsActive(!open);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, setOpen]);

  useEffect(() => {
    if (!isActive) {
      setOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (dropdownRef) {
      dropdownRef({ setOpen, open });
    }
  }, [open]);

  return (
    <Box display="inline-block" style={containerStyle}>
      <Box
        onClick={(event) => {
          event.stopPropagation();
          handleOpen();
        }}
        cursor="pointer"
        ref={setReferenceElement}
      >
        {label}
      </Box>
      {open && (
        <Box
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 1,
            minWidth: isContentCentered ? (referenceElement && referenceElement.scrollWidth) || 0 : 0,
            ...popperStyle,
          }}
          {...attributes.popper}
          {...props}
        />
      )}
    </Box>
  );
};

/**
 * Possible refactor
 * 1. Check if label component is string and use Text component to inherit styles
 * 2. Mount, unmount animation
 * 3. Add useDetectOutsideClick
 */

export default Dropdown;
