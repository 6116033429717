import { useRef, useState, useCallback } from "react";

export const useControlledState = (value, defaultValue, onChange) => {
  let paramValue = value;
  const [stateValue, setStateValue] = useState(paramValue || defaultValue);
  const isControlled = paramValue !== undefined;
  const ref = useRef(isControlled);
  const wasControlled = ref.current;
  const internalState = useRef(stateValue);

  if (wasControlled !== isControlled) {
    console.warn(`WARN: Component changed from ${wasControlled ? "controlled" : "uncontrolled"} to ${isControlled ? "controlled" : "uncontrolled"}.`);
  }
  ref.current = isControlled;

  const setValue = useCallback(
    (v, ...args) => {
      const onChangeCallback = (val, ...cbArgs) => {
        if (onChange && !Object.is(internalState.current, val)) {
          onChange(val, ...cbArgs);
        }
        if (!isControlled) {
          internalState.current = val;
        }
      };

      if (!isControlled) {
        setStateValue(v);
      }
      onChangeCallback(v, ...args);
      if (typeof v === "function") {
        //  https://reactjs.org/docs/hooks-reference.html#functional-updates
        const updateFunction = (oldValue, ...functionArgs) => {
          const interceptedValue = v(isControlled ? internalState.current : oldValue, ...functionArgs);
          onChangeCallback(interceptedValue, ...args);
          if (!isControlled) {
            return interceptedValue;
          }
          return oldValue;
        };
        setStateValue(updateFunction);
      } else {
        if (!isControlled) {
          setStateValue(v);
        }
        onChangeCallback(v, ...args);
      }
    },
    [isControlled, onChange]
  );
  // If/when controlled component's value prop changes, we need to update internalState
  if (isControlled) {
    internalState.current = paramValue;
  } else {
    paramValue = stateValue;
  }
  return [paramValue, setValue];
};
