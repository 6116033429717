import React from "react";
import Button from "./Button";
import OutlinedButton from "./OutlinedButton";

const PrimaryButton = (props) => <Button {...props} bg="primary" bgHovered="primaryDark" color="white" />;

PrimaryButton.propTypes = Button.propTypes;

const PrimaryOutlinedButton = (props) => <OutlinedButton {...props} borderColor="primary" color="primary" />;

PrimaryOutlinedButton.propTypes = Button.propTypes;

export { PrimaryButton, PrimaryOutlinedButton };
