import React from "react";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
  html, body, * {
    font-family: 'Inter', sans-serif;  
    -webkit-font-smoothing: antialiased;
  }
  button {
    font-family: 'Inter', sans-serif !important;  
  }
`;

const ThemeProvider = ({ children, ...props }) => (
  <StyledThemeProvider {...props}>
    <GlobalStyle />
    {children}
  </StyledThemeProvider>
);

export default ThemeProvider;
