import React from "react";
import PropTypes from "prop-types";
import { Body } from "../Text/Text";

export const BadgeColorTypes = {
  default: { bg: "#E8EAED", color: "fullShade" },
  resolve: { bg: "#DCF7E8", color: "success" },
  public: { bg: "#FEF7ED", color: "warning" },
  alert: { bg: "#feeded", color: "danger" },
};

/**
 * Badge component.
 * @params0 props - Override properties from botero {Body} component
 * @params1 props.type - default badge string types 'default' | 'resolve' | 'public' | 'alert'
 * @params2 props.children - Any text, string or dom element and component
 * @returns {JSX.Element}
 * @constructor
 */
const Badge = (props) => {
  const type = BadgeColorTypes[props.type];

  return (
    <Body small as="span" bg={props.bg || type.bg} color={props.color || type.color} px={1} py="2px" ml={1} borderRadius={6} {...props}>
      {props.children}
    </Body>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(["default", "resolve", "public", "alert", PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  children: PropTypes.any,
};

Badge.defaultProps = {
  type: "default",
  children: null,
};

export { Badge };
