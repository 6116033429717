import React, { useEffect } from "react";
import styled from "styled-components";
import BaseComponent from "../Base/BaseComponent";

const StyledBox = styled(BaseComponent)`
  transition: all 0.2s linear;
`;

const Overlay = (props) => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.style.overflow = "auto";
    };
  });
  return <StyledBox position="fixed" top={0} left={0} height="100vh" width="100%" bg="rgb(163, 169, 177)" zIndex={998} opacity={0.4} {...props} />;
};

export default Overlay;
