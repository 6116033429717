import styled, { keyframes } from "styled-components";
import BaseComponent from "../Base/BaseComponent";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(41px) translateY(0px);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

export const StyledOverlay = styled(BaseComponent)`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  transition: all 0.2s linear;
  z-index: 9999;
`;

export const StyledModal = styled(BaseComponent)`
  margin: auto;
  background: white;
  border: 1px solid #9f9f9f;
  width: max-content;
  max-height: 85vh;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  animation: ${fadeInUp} 0.1s linear;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  padding: 32px;
`;

export const StyledContainer = styled(BaseComponent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
