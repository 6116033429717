/* eslint global-require: 0 */
const ASSETS = {
  BOTMD_LOGO_LARGE: require("../assets/logo-large.svg"),
  BOTMD_LOGO_SMALL: require("../assets/logo-small.svg"),
  BOT_BADGE: require("../assets/avatar-badge.svg"),
  BOT_TABLE_AVATAR: require("../assets/bot-table-avatar.svg"),
  BOT_FILE_AVATAR: require("../assets/bot-file-avatar.svg"),
  BOT_CRYING: require("../assets/crying-avatar.svg"),
  BOT_SENDING: require("../assets/message-sending.gif"),
  BOT_SENDING_COMPLETE: require("../assets/message-sending-complete.gif"),
  SPAIN_FLAG: require("../assets/es.svg"),
  INDO_FLAG: require("../assets/id.svg"),
  UK_FLAG: require("../assets/gb.svg"),
};

export { ASSETS };
