import colors from "./colors";

export default {
  breakpoints: ["40em", "52em", "64em"],
  colors,
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    medium: "0px 6px 12px 0px #98A2B326, 0px 4px 4px 0px #98A2B326, 0px 2px 2px 0px #98A2B326",
    large: "0px 2px 2px 0px #414E651A, 0px 4px 4px 0px #414E651A, 0px 6px 12px 0px #414E651A, 0px 12px 24px 0px #414E651A",
  },
  elevation: {
    0: "0 0px 0px rgba(0,0,0,0.0)",
    1: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    2: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    3: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    4: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    5: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  },
};
