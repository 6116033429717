import React, { useState } from "react";
import { chain, filter, isEqual, map } from "lodash";
import styled from "styled-components";

import Button from "../../Button/Button";
import Flex from "../../Flex/Flex";
import { Body } from "../../Text/Text";
import theme from "../../../theme/theme";

import { defaultDays } from "./Calendar";

const BodyWrapper = styled(Body)``;

const WeekItem = ({ day, active, onChange, itemTextProps, ...props }) => {
  /* eslint no-unused-vars: 0 */ // This need to be removed if not used
  const [hover, setHover] = useState(false);

  const handleOnChange = () => {
    setHover(false);
    onChange(day);
  };

  return (
    <Button mr={2} width="48px" height="48px" borderRadius="50%" cursor="pointer" bg={active ? theme.colors.primary : theme.colors.lightestShade} onClick={handleOnChange} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...props}>
      <BodyWrapper color={active ? "white" : "darkestShade"} medium {...itemTextProps}>
        {day.label}
      </BodyWrapper>
    </Button>
  );
};

const WeekSelector = ({ allDays = defaultDays, onChange, selectedDays = [], itemProps, itemTextProps, ...props }) => {
  const isSelectedDay = (day) => {
    return !chain(selectedDays).find(day).isEmpty().value();
  };

  const handleOnChange = (day) => {
    if (isSelectedDay(day)) {
      onChange(filter(selectedDays, (selectedDay) => !isEqual(selectedDay.id, day.id)));
    } else {
      onChange([...selectedDays, day]);
    }
  };
  return (
    <Flex flexWrap="wrap" {...props}>
      {map(allDays, (day, index) => (
        <WeekItem key={index} day={day} active={isSelectedDay(day)} onChange={handleOnChange} itemTextProps={itemTextProps} {...itemProps} />
      ))}
    </Flex>
  );
};

export default WeekSelector;
