import React from "react";
import styled from "styled-components";
import Box from "../Box/Box";
import BaseComponent from "../Base/BaseComponent";
import { directions } from "./helper";
import Overlay from "../Overlay/Overlay";

const StyledDrawer = styled(BaseComponent)((props) => ({
  transition: "all 0.1s ease-out",
  overflow: "scroll",
  ...directions(props.open)[props.direction],
}));

const Drawer = ({ width = 300, height = "100vh", bg = "white", zIndex = 999, open = false, direction = "right", onClose, ...props }) => {
  return (
    <Box>
      {open && <Overlay onClick={onClose} />}
      <StyledDrawer position="fixed" height={height} width={width} backgroundColor={bg} zIndex={zIndex} boxShadow="small" open={open} direction={direction} {...props} />
    </Box>
  );
};

export default Drawer;
