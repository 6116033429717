import React from "react";
import styled from "styled-components";
import Box from "../Box/Box";
import BaseComponent from "../Base/BaseComponent";

const TableContainer = styled(BaseComponent)`
  border-spacing: 0;
`;

const StyledTableRow = styled(Box)`
  tbody > &:hover {
    background: #f4f6f8;
  }
  tbody > & {
    box-shadow: inset 0px 1px 0px #d5d9de;
  }
`;

TableContainer.defaultProps = { as: "table", fontSize: 1, fontWeight: 400 };

const TableHead = (props) => <Box as="thead" {...props} />;

const TableBody = (props) => <Box as="tbody" {...props} />;

const TableRow = (props) => <StyledTableRow as="tr" bg="white" {...props} />;

const Cell = styled(BaseComponent)`
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

Cell.defaultProps = {
  px: 2,
  py: 3,
};

const TableCell = (props) => <Cell {...props} as="td" />;

const TableHeadCell = (props) => <Cell fontWeight={600} opacity={0.7} {...props} as="th" />;

export { TableContainer, TableHead, TableRow, TableCell, TableHeadCell, TableBody };
