import React from "react";
import { forEach } from "lodash";
import { LogContextProvider, LogContext } from "./LogContextProvider";
import { sendLog } from "../utils/LoggingUtils";

const getEventHandlers = (child, consumedProps, actionProps, eventName = "") => {
  const eventHandlers = {};
  forEach(actionProps, (specificHandlerProps, eventHandlerName) => {
    eventHandlers[eventHandlerName] = (...eventHandlerArgs) => {
      child.props[eventHandlerName]?.apply(child, eventHandlerArgs);
      const selectedProps = consumedProps.combinedProps;
      const props = { ...selectedProps, ...specificHandlerProps };
      sendLog(props, eventName); // -> invokes amplitude send event data
    };
  });

  return eventHandlers;
};

const LogEvent = (props) => {
  const { elementType, actionProps, logEventProps, children } = props;
  if (!logEventProps || !actionProps) {
    return children;
  }

  return (
    <LogContextProvider elementType={elementType} eventName={logEventProps.eventName} {...logEventProps}>
      <LogContext.Consumer>
        {(consumedProps) => {
          return React.Children.map(children, (child) => React.cloneElement(child, getEventHandlers(child, consumedProps, actionProps, logEventProps.eventName)));
        }}
      </LogContext.Consumer>
    </LogContextProvider>
  );
};

export default LogEvent;
