const bindFunctionOrClass = (fnOrClass, args) => {
  const boundObj = fnOrClass.bind(null, ...args);
  // Ensures that all functions including statics are bound correctly
  //
  Object.getOwnPropertyNames(fnOrClass)
    .filter((n) => n !== "prototype")
    .forEach((name) => {
      const descriptor = Object.getOwnPropertyDescriptor(boundObj, name);
      if (descriptor && !descriptor.writable) {
        return;
      }
      boundObj[name] = fnOrClass[name];
    });
  return boundObj;
};

export default class QueryResolver {
  static fromQueries(queries) {
    const resolver = new QueryResolver();
    Object.keys(queries).forEach((queryKey) => {
      resolver.register(queryKey, queries[queryKey]);
    });
    return resolver;
  }

  constructor() {
    this.queries = new Map();
    this.boundQueries = new Map();
  }

  clearCache() {
    this.boundQueries = new Map();
  }

  register(key, query, options = {}) {
    if (typeof query !== "function") {
      throw new Error(`Query with ${key} of type ${typeof query} must be function or class.`);
    }
    this.queries.set(key, { query, options });
  }

  resolve(queries) {
    if (Array.isArray(queries)) {
      return queries.reduce(
        (result, key) => ({
          ...result,
          [key]: this.resolveQuery(key),
        }),
        {}
      );
    }
    /* eslint-disable no-param-reassign */
    if (!!queries && typeof queries === "object") {
      return Object.entries(queries).reduce(
        (result, [key, name]) => ({
          ...result,
          [name]: this.resolveQuery(key),
        }),
        {}
      );
    }
    throw new Error(`Unsupported list of queries provided. Only arrays and objects are allowed. Got: ${JSON.stringify(queries)}`);
  }

  resolveQuery = (key) => {
    if (!this.queries.has(key)) {
      throw new Error(`No query matching key: ${toString(key)}`);
    }

    const { query, options } = this.queries.get(key);
    if (options.params) {
      if (!this.boundQueries.has(key)) {
        const args = Array.isArray(options.params) ? options.params : [];
        const boundFn = bindFunctionOrClass(query, args);
        this.boundQueries.set(key, boundFn);
      }
      const boundQuery = this.boundQueries.get(key);
      return boundQuery;
    }

    return query;
  };
}
