import React from "react";
import Flex from "../Flex/Flex";
import { H5, Body } from "../Text/Text";
import Box from "../Box/Box";
import BotAvatar from "../../assets/bot-table-avatar.svg";

const NoResults = React.memo(({ avatar = BotAvatar, title = "No results found", description = "Try adjusting your search or filter to find what you’re looking for.", avatarProps = {} }) => (
  <Flex pt={30} justifyContent="center" borderTop="1px solid #d5d9de">
    <Flex alignItems="center" flexDirection="column">
      <Box as="img" src={avatar} height={96} width={96} alt="Bot Avatar" {...avatarProps} />
      <H5 mt={20}>{title}</H5>
      <Body small>{description}</Body>
    </Flex>
  </Flex>
));

export default NoResults;
