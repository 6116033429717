/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from "react";

export const useDetectOutsideClick = (triggerEl, el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      const isTriggerElement = triggerEl && triggerEl.contains(e.target);
      const isOutsideElement = el && !el.contains(e.target);
      // If the active element exists and is clicked outside of
      if (!isTriggerElement && isOutsideElement) {
        setIsActive(!isActive);
      }
    };

    // { capture:true } is required for event listener in React 17.
    // Refer: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick, { capture: true });
    }

    return () => {
      window.removeEventListener("click", onClick, { capture: true });
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
