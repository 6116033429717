export default {
  H1: {
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: "semiBold",
    letterSpacing: "-0.24px",
  },
  H2: {
    fontSize: 24,
    lineHeight: "36px",
    fontWeight: "semiBold",
    letterSpacing: "-0.24px",
  },
  H3: {
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: "semiBold",
    letterSpacing: "-0.2px",
  },
  H4: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "semiBold",
    letterSpacing: "-0.32px",
  },
  H5: {
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: "semiBold",
    letterSpacing: "-0.08px",
  },
  H6: {
    fontSize: 12,
    lineHeight: "20px",
    fontWeight: "semiBold",
    letterSpacing: "-0.08px",
  },
};
