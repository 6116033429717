import { useContext } from "react";
import { ApiContext } from "./ApiProvider";

export default ({ queries: queryKeys } = {}) => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error(`useApi hook must be used within ApiProvider`);
  }
  if (queryKeys) {
    return {
      queries: context.queryResolver.resolve(queryKeys),
      ...context,
    };
  }
  return context;
};
