import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionHeader, AccordionBody } from "../../Accordion/Accordion";
import Box from "../../Box/Box";

/**
 * Accordion widget component
 * @param0 data - List of data Map array = [{key: string, isOpen: boolean,type: string, ...].
 * @param1 headerProps - Override props for botero {AccordionHeader} component. Defaults are {fontSize: '0.9em',height: 10}.
 * @param2 bodyProps - Override props for botero {AccordionBody} component. Defaultes are {fontSize: '0.9em'}.
 * @param3 width - (option) Max width size for the overall accordion element. Defaults is {300}.
 * @param4 alternateBgColor - (option) Set false to disable alternate bg item color. Default is true.
 * @param5 renderHeader - Accordion header string or component. Default is null.
 * @param6 renderBody - Accordion body string or component. Default is null.
 * @param7 containerProps - Override props for botero {AccordionBody} box container...
 * @returns {JSX.Element}
 * @constructor
 */
const AccordionWidget = ({ data, containerProps, headerProps, bodyProps, width, alternateBgColor, renderHeader, renderBody, accordionProps }) => {
  const [list, setList] = useState([...data]);
  const borderColor = "1px solid #D5D7DE";

  useEffect(() => {
    if (data && data.length > 0) {
      setList([...data]);
    }
  }, [data]);

  const isFirstRow = (index) => index === 0;
  const isLastRow = (index) => index === list?.length - 1;

  const Header = (props) => <AccordionHeader borderTop="1px solid #ccc" {...props} />;

  const updateListState = (item) => {
    setList((l) => l.map((m) => (m.uid === item.uid ? { ...m, isOpen: !item.isOpen } : { ...m })));
  };

  return (
    <Box borderRadius={8} borderLeft={borderColor} borderRight={borderColor} borderBottom={borderColor} borderBottomLeftRadius={8} borderBottomRightRadius={8} maxWidth={width} {...containerProps}>
      {list &&
        list.map((o, index) => {
          const topBorderRadius = isFirstRow(index) ? 8 : 0;
          const bottomBorderRadius = isLastRow(index) && !o.isOpen ? 8 : 0;
          const defaultBgColor = ["rgba(255,255,255,0)", "#FAFBFC"];
          const itemBgColor = index % 2 && alternateBgColor ? defaultBgColor[0] : defaultBgColor[1];
          return (
            <Accordion key={`${o.uid}`} open={o.isOpen} {...accordionProps}>
              {renderHeader ? (
                <Header
                  render={() => renderHeader(o)}
                  onToggle={() => {
                    updateListState(o);
                  }}
                  index={index}
                  bg={itemBgColor}
                  borderTopLeftRadius={topBorderRadius}
                  borderTopRightRadius={topBorderRadius}
                  borderBottomLeftRadius={bottomBorderRadius}
                  borderBottomRightRadius={bottomBorderRadius}
                  {...headerProps}
                />
              ) : (
                <></>
              )}

              <AccordionBody bg={itemBgColor} borderTopLeftRadius={!renderHeader ? topBorderRadius : 0} borderTopRightRadius={!renderHeader && topBorderRadius} borderBottomLeftRadius={isLastRow(index) && o.isOpen && 7} borderBottomRightRadius={isLastRow(index) && o.isOpen && 7} borderTop={renderHeader && renderBody ? null : borderColor} {...bodyProps}>
                {renderBody(o, index)}
              </AccordionBody>
            </Accordion>
          );
        })}
    </Box>
  );
};

AccordionWidget.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.arrayOf(PropTypes.string),
      isOpen: PropTypes.bool,
    })
  ).isRequired,
  containerProps: PropTypes.object,
  headerProps: PropTypes.object,
  bodyProps: PropTypes.object,
  width: PropTypes.node,
  alternateBgColor: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
};

AccordionWidget.defaultProps = {
  containerProps: {},
  headerProps: {
    fontSize: "14px",
    height: 10,
  },
  bodyProps: {
    fontSize: "14px",
  },
  width: 300,
  alternateBgColor: true,
  renderHeader: null,
  renderBody: null,
};

export default AccordionWidget;
