import React from "react";
import Button from "./Button";
import OutlinedButton from "./OutlinedButton";

const DangerButton = (props) => <Button {...props} bg="danger" bgHovered="dangerDark" color="white" />;

DangerButton.propTypes = Button.propTypes;

const DangerOutlinedButton = (props) => <OutlinedButton {...props} borderColor="danger" color="danger" />;

DangerOutlinedButton.propTypes = Button.propTypes;

export { DangerButton, DangerOutlinedButton };
