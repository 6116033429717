import React from "react";
import { Radio as RRadio } from "@rebass/forms";
import styled from "styled-components";
import LogEvent from "../../log/LogEvent";

const RadioStyled = styled(RRadio)`
  cursor: pointer;
  input:focus ~ && {
    background-color: transparent;
  }
  input:checked ~ && {
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;

const Radio = (props) => (
  <LogEvent logEventProps={props.logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="button">
    <RadioStyled data-testid={props.testid} {...props} />
  </LogEvent>
);

Radio.defaultProps = {
  color: "primary",
};

export { Radio };
