import React from "react";
import Flex from "../Flex/Flex";
import { Text } from "../Text/Text";
import LogEvent from "../../log/LogEvent";

const Chip = ({ children, logEventProps, ...props }) => (
  <LogEvent logEventProps={logEventProps} actionProps={{ onClick: { action: "click" } }} elementType="chip">
    <Flex alignItems="center">
      <Text width="12px" fontSize="12px" p="5px" color="primary" bg="#BEDBFD50" borderRadius="50%" fontWeight="bold" textAlign="center" style={{ boxSizing: "content-box" }} {...props}>
        {children || props.label}
      </Text>
    </Flex>
  </LogEvent>
);

export default Chip;
