const colors = {
  primary: "#256BF6",
  primaryWithOpacity: {
    "08": "#256bf614",
  },
  primaryDark: "#0041C2",
  primaryLight: "#7198FF",
  success: "#27AE60",
  successWithOpacity: {
    "08": "#27AE6014",
  },
  warning: "#F3AD3D",
  warningWithOpacity: {
    "08": "#F3AD3D14",
  },
  danger: "#E05138",
  dangerDark: "#9a230e",
  dangerWithOpacity: {
    "08": "#E0513814",
  },
  emptyShade: "#FFFFFF",
  lightestShade: "#F4F6F8",
  lightShade: "#EEF0F2",
  mediumShade: "#D5D9DE",
  darkShade: "#A3A9B1",
  darkestShade: "#697481",
  fullShade: "#111824",
  lightBlue: "#E9F0FE",
  grey: "#19181A",
};

export default colors;
