import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { H5 } from "../../Text/Text";
import Box from "../../Box/Box";

const BoxWidget = forwardRef(({ children, bodyProps, title, titleComponent = null, variant = "primary", ...props }, ref) => (
  <Box border="solid" borderWidth={1} borderColor="lightShade" borderRadius={8} my={1} mx={1} ref={ref} {...props}>
    {["primary", "warning", "success", "danger"].includes(variant) && <Box border="solid" borderWidth={3} borderColor={variant} borderTopLeftRadius={8} borderTopRightRadius={8} />}
    <Box p={3} {...bodyProps}>
      {titleComponent || <H5 mb={2}>{title}</H5>}
      {children}
    </Box>
  </Box>
));

BoxWidget.propTypes = {
  ...Box.propTypes,
  variant: PropTypes.oneOf(["primary", "warning", "success", "danger", "outlined"]),
  titleComponent: PropTypes.node,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // it can be '64em'
};
BoxWidget.defaultProps = {
  maxWidth: 456,
  titleComponent: null,
  title: "",
  variant: "primary",
};

export default BoxWidget;
