import React from "react";
import styled from "styled-components";

const ArrowUp = styled.div.attrs(({ arrowSize }) => ({
  arrowSize: arrowSize || 4.5,
}))`
  width: 0;
  height: 0;
  cursor: pointer;
  border-style: solid;
  border-width: ${({ arrowSize }) => `0 ${arrowSize}px ${arrowSize + 0.5}px ${arrowSize}px`};
  border-color: ${({ active, theme: { colors } }) => `transparent transparent ${active ? colors.darkestShade : colors.mediumShade} transparent`};
`;

const ArrowDown = styled(ArrowUp)`
  margin-top: 2px;
  border-width: ${({ arrowSize }) => `${arrowSize + 0.5}px ${arrowSize}px 0 ${arrowSize}px`};
  border-color: ${({ active, theme: { colors } }) =>
    `${active ? colors.darkestShade : colors.mediumShade} transparent
    transparent transparent`};
`;

export default ({ down, ...props }) => (down ? <ArrowDown {...props} /> : <ArrowUp {...props} />);
