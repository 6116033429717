import styled, { css } from "styled-components";
import { space, layout, typography, color, flexbox, border, position, shadow, opacity } from "styled-system";

const cursor = css`
  cursor: ${({ cursor: pointer }) => pointer || null};
`;

// Exporting a base component to have shared features in future.
const BaseComponent = styled("div")`
  ${space};
  ${layout};
  ${color};
  ${flexbox};
  ${typography};
  ${border};
  ${position};
  ${shadow};
  ${opacity};
  ${cursor};
`;

export default BaseComponent;
