import React from "react";
import PropTypes from "prop-types";
import Box from "../Box/Box";
import Flex from "../Flex/Flex";
import { H5, Body } from "../Text/Text";

const Alert = ({ title, subTitle, children, image, ...props }) => (
  <Box p={3} maxWidth={350} border="solid" borderWidth={1} borderColor="lightShade" borderRadius={8} width="100%" {...props}>
    {children || (
      <>
        <Flex>
          {image && <Box as="img" src={image} />}
          <Box px={2}>
            <H5>{title}</H5>
            <Body color="darkestShade" small>
              {subTitle}
            </Body>
          </Box>
        </Flex>
      </>
    )}
  </Box>
);

Alert.propTypes = {
  ...Box.propTypes,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

Alert.defaultProps = {
  subTitle: "",
  image: null,
  title: "",
};

export default Alert;
