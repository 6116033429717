import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

const isValidSessionToken = (token) => token && !/null/i.test(token);

const AuthProvider = ({ loginUrl, redirectPath, initialState, onLogin, onLogout, sessionQueryKey = "heimdallSession", ...props }) => {
  const [authState, setAuthState] = useState({
    authenticated: false,
    token: null,
    ...initialState,
  });

  const login = async (params) => {
    if (params && params.token && !/null/i.test(params.token)) {
      setAuthState({ authenticated: true, ...params });
      return;
    }
    if (loginUrl) {
      window.location = loginUrl;
      return;
    }
    if (onLogin) {
      const nextAuthState = await onLogin(authState);
      setAuthState({ ...authState, ...nextAuthState });
    }
  };
  const logout = () => {
    if (onLogout) {
      onLogout();
    }
    setAuthState({ authenticated: false, token: null });
  };

  const onLoginCallback = async (sessionToken) => {
    const nextAuthState = onLogin ? await onLogin(sessionToken) : {};
    setAuthState({
      ...authState,
      authenticated: isValidSessionToken(sessionToken),
      token: sessionToken,
      ...(nextAuthState || {}),
    });
  };

  useEffect(() => {
    const sessionToken = new URLSearchParams(document.location.search).get("session");
    if (redirectPath === document.location.pathname && sessionToken) {
      onLoginCallback(sessionToken);
    }
  }, [document.location.pathname, redirectPath]);

  return <AuthContext.Provider value={{ login, logout, authState, sessionQueryKey }} {...props} />;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth hook must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
